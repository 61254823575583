/*
 * Legal Disclaimer
 *
 * These Fonts are licensed only for use on these domains and their subdomains:
 * www.infura.io
 *
 * It is illegal to download or use them on other websites.
 *
 * While the @font-face statements below may be modified by the client, this
 * disclaimer may not be removed.
 *
 * Lineto.com, 2017
 */


/*
 *
 * INSTRUCTIONS
 *
 * Copy the Legal Disclaimer and the @font-faces statements to your regular CSS file.
 * The fonts folder(s) should be placed relative to the regular CSS file.
 *
 * You can use either the complete or subsetted fonts:
 * If you don’t require the entire range of characters, you can use the smaller, subsetted webfonts instead.
 * See "Glyphs & Languages" for an overview of the characters in the corresponding html file in the root directory of this package.
 *
 */


/* Complete */

@font-face {
    font-family: "Lineto Akkurat Regular";
    src: url("fonts/lineto-akkurat-regular.eot");
    src: url("fonts/lineto-akkurat-regular.eot?#iefix") format("embedded-opentype"),
         url("fonts/lineto-akkurat-regular.woff2") format("woff2"),
         url("fonts/lineto-akkurat-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}


/* Subsetted */

@font-face {
    font-family: "Lineto Akkurat Regular Sub";
    src: url("fonts_subsetted/lineto-akkurat-regular.eot");
    src: url("fonts_subsetted/lineto-akkurat-regular.eot?#iefix") format("embedded-opentype"),
         url("fonts_subsetted/lineto-akkurat-regular.woff2") format("woff2"),
         url("fonts_subsetted/lineto-akkurat-regular.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}