@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @import url('./assets/fonts/LLAkkurat-LightWeb/css/stylesheet.css');
  @import url('./assets/fonts/LLAkkurat-RegularWeb/css/stylesheet.css');
  @import url('./assets/fonts/LLAkkuratMonoWeb/css/stylesheet.css');
  @import url('./assets/fonts/LLAkkurat-RegularOTStd/css/stylesheet.css');

  * {
    box-sizing: border-box;
  }

  html,
  body {
    font-family: 'Lineto Akkurat Light';
    @apply text-white bg-black;
  }

  body {
    background-image: url('./assets/images/consensys_Atmosphere-gradients-all-around.png');
    background-color: #2d58e0;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center 100px;
    background-size: contain;
  }

  @media screen and (min-width: 1440px) {
    body {
      background-position: center;
    }
  }
}

@layer components {
  .hero-heading {
    @apply mb-6 text-4xl whitespace-nowrap px-9 tablet:px-0 desktop:text-6xl desktop:mb-4;
  }

  .hero-lead-text {
    @apply max-w-lg mb-8 text-xs tablet:text-base desktop:text-lg desktop:max-w-none px-9 tablet:px-2.5;
  }
}

.email-submitted-gradient {
  background: linear-gradient(180deg, #3c3c3c 0%, rgba(32, 32, 32, 0.62) 100%),
    linear-gradient(180deg, #363636 0%, rgba(0, 0, 0, 0.01) 100%);
}

.loot-gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.01) 0%, #212121 100%);
  box-shadow: 0px 26px 69px rgba(0, 0, 0, 0.46);
}

.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
}

.spinner-with-logo {
  z-index: 2;
  position: absolute;
  width: 250px;
  height: 250px;
  top: -270px;
}

.spinner-with-logo .spinner {
  position: absolute;
}

@media screen and (min-width: 768px) and (max-width: 1439px) {
  .spinner-with-logo {
    top: -300px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1439px) and (max-height: 900px) {
  .spinner-with-logo {
    top: -250px;
  }
}

@media screen and (max-width: 1439px) and (max-height: 700px) {
  .spinner-with-logo {
    width: 150px;
    height: 150px;
    left: -75px;
    top: -200px;
  }

  .spinner-with-logo .infura-logo {
    width: 100px;
    top: 50px;
    left: 25px;
  }

  .spinner-with-logo .progress-info {
    top: 50px;
  }
}

@media screen and (max-width: 1439px) {
  .loot-gradient {
    background: linear-gradient(
      180deg,
      rgba(33, 33, 33, 0.4) 0%,
      rgba(0, 0, 0, 0.004) 100%
    );
  }
}

@media screen and (min-width: 1440px) {
  .spinner-with-logo {
    width: 300px;
    height: 300px;
    left: 50px;
    top: -150px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
